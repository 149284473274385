import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

router.beforeEach((to, from, next) => {
  const prefetchLinks = getPrefetchLinks(to);
  prefetchLinks.forEach((link) => {
    const prefetchLink = document.createElement("link");
    prefetchLink.rel = "prefetch";
    prefetchLink.href = link;
    document.head.appendChild(prefetchLink);
  });

  next();
});

const app = createApp(App);
app.use(router);
app.mount("#app");

function getPrefetchLinks(to) {
  const prefetchLinks = [];

  if (to.meta.prefetchResources) {
    const resources = to.meta.prefetchResources;
    resources.forEach((resource) => {
      prefetchLinks.push(resource);
    });
  }
  return prefetchLinks;
}
