const local = "localhost";
const pro = "www.risingsunonline.fun";
import isProduction from "@/gameconfig";

export function getUsersInfo(param) {
  const { userId, token } = param;
  const url = `http://${isProduction ? pro : local}:2567/user/getLoginUserInfo`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      userId: userId,
    }),
  }).then((res) => res.json());
}

export function getUserInfoByIds(user) {
  const { loginId } = user;
  const url = `http://${isProduction ? pro : local}:2567/user/getUsersInfo`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      loginIds: loginId,
    }),
  }).then((res) => res.json());
}

export function register(user) {
  const url = `http://${isProduction ? pro : local}:2567/user/register`;
  const formData = new FormData();
  Object.keys(user).forEach((key) => {
    formData.append(key, user[key]);
  });
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

export function login(user) {
  const url = `http://${isProduction ? pro : local}:2567/user/login`;
  const formData = new FormData();
  Object.keys(user).forEach((key) => {
    formData.append(key, user[key]);
  });
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

export function uniqueRegister(fields, data) {
  const url = `http://${isProduction ? pro : local}:2567/user/uniqueRegister`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fields,
      data,
    }),
  }).then((res) => res.json());
}

export function getRankApi() {
  const url = `http://${isProduction ? pro : local}:2567/api/rank`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}
