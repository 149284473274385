export default {
  card: {
    red_card_cheng: {
      id: "0",
      name: "red_card_cheng",
      url: "/assets/card/red_card_cheng.png",
    },
    red_card_warrior: {
      id: "0",
      name: "red_card_warrior",
      url: "/assets/card/red_card_warrior.png",
    },
    red_card_monk: {
      id: "0",
      name: "red_card_monk",
      url: "/assets/card/red_card_monk.png",
    },
    red_card_general: {
      id: "0",
      name: "red_card_general",
      url: "/assets/card/red_card_general.png",
    },
    purple_card_cheng: {
      id: "0",
      name: "purple_card_cheng",
      url: "/assets/card/purple_card_cheng.png",
    },
    purple_card_warrior: {
      id: "0",
      name: "purple_card_warrior",
      url: "/assets/card/purple_card_warrior.png",
    },
    purple_card_monk: {
      id: "0",
      name: "purple_card_monk",
      url: "/assets/card/purple_card_monk.png",
    },
    purple_card_general: {
      id: "0",
      name: "purple_card_general",
      url: "/assets/card/purple_card_general.png",
    },
    green_card_cheng: {
      id: "0",
      name: "green_card_cheng",
      url: "/assets/card/green_card_cheng.png",
    },
    green_card_warrior: {
      id: "0",
      name: "green_card_warrior",
      url: "/assets/card/green_card_warrior.png",
    },
    green_card_monk: {
      id: "0",
      name: "green_card_monk",
      url: "/assets/card/green_card_monk.png",
    },
    green_card_general: {
      id: "0",
      name: "green_card_general",
      url: "/assets/card/green_card_general.png",
    },
    blue_card_cheng: {
      id: "0",
      name: "blue_card_cheng",
      url: "/assets/card/blue_card_cheng.png",
    },
    blue_card_warrior: {
      id: "0",
      name: "blue_card_warrior",
      url: "/assets/card/blue_card_warrior.png",
    },
    blue_card_monk: {
      id: "0",
      name: "blue_card_monk",
      url: "/assets/card/blue_card_monk.png",
    },
    blue_card_general: {
      id: "0",
      name: "blue_card_general",
      url: "/assets/card/blue_card_general.png",
    },
    yellow_card_cheng: {
      id: "0",
      name: "yellow_card_cheng",
      url: "/assets/card/yellow_card_cheng.png",
    },
    yellow_card_warrior: {
      id: "0",
      name: "yellow_card_warrior",
      url: "/assets/card/yellow_card_warrior.png",
    },
    yellow_card_monk: {
      id: "0",
      name: "yellow_card_monk",
      url: "/assets/card/yellow_card_monk.png",
    },
    yellow_card_general: {
      id: "0",
      name: "yellow_card_general",
      url: "/assets/card/yellow_card_general.png",
    },
    order_1: {
      name: "order_1",
      url: "/assets/card/1.png",
    },
    order_2: {
      name: "order_2",
      url: "/assets/card/2.png",
    },
    order_3: {
      name: "order_3",
      url: "/assets/card/3.png",
    },
    order_4: {
      name: "order_4",
      url: "/assets/card/4.png",
    },
    order_5: {
      name: "order_5",
      url: "/assets/card/5.png",
    },
    temple_0: {
      name: "temple_0",
      url: "/assets/temple/temple0.jpg",
    },
    temple_1: {
      name: "temple_1",
      url: "/assets/temple/temple1.jpg",
    },
    temple_2: {
      name: "temple_2",
      url: "/assets/temple/temple2.jpg",
    },
    temple_3: {
      name: "temple_3",
      url: "/assets/temple/temple3.jpg",
    },
    temple_4: {
      name: "temple_4",
      url: "/assets/temple/temple4.jpg",
    },
    temple_5: {
      name: "temple_5",
      url: "/assets/temple/temple5.jpg",
    },
    temple_6: {
      name: "temple_6",
      url: "/assets/temple/temple6.jpg",
    },
    ranger: {
      name: "ranger",
      url: "/assets/ui/ranger.png",
    },
    boqvan: {
      name: "boqvan",
      url: "/assets/card/boqvan.png",
    },
    renai: {
      name: "renai",
      url: "/assets/card/ren.png",
    },
    jiangjunlin: {
      name: "jiangjunlin",
      url: "/assets/card/jiangjunlin.png",
    },
    kulougui: {
      name: "kulougui",
      url: "/assets/card/kulougui.png",
    },
    xiongshidao: {
      name: "xiongshidao",
      url: "/assets/card/xiongshidao.png",
    },
    zhengzhi: {
      name: "zhengzhi",
      url: "/assets/card/zhengzhi.png",
    },
    youfutongxiang: {
      name: "youfutongxiang",
      url: "/assets/card/youfutongxiang.png",
    },
    yingyuandao: {
      name: "yingyuandao",
      url: "/assets/card/yingyuandao.png",
    },
    langrenzhanfa: {
      name: "langrenzhanfa",
      url: "/assets/card/langrenzhanfa.png",
    },
    qian: {
      name: "qian",
      url: "/assets/card/qian.png",
    },
    yi: {
      name: "yi",
      url: "/assets/card/yi.png",
    },
    wushidao: {
      name: "wushidao",
      url: "/assets/card/wushidao.png",
    },
    jvmangdao: {
      name: "jvmangdao",
      url: "/assets/card/jvmangdao.png",
    },
    junfadao: {
      name: "junfadao",
      url: "/assets/card/junfadao.png",
    },
    youling: {
      name: "youling",
      url: "/assets/card/youling.png",
    },
    zhong: {
      name: "zhong",
      url: "/assets/card/zhong.png",
    },
    hunpogui: {
      name: "hunpogui",
      url: "/assets/card/hunpogui.png",
    },
    hu: {
      name: "hu",
      url: "/assets/card/hu.png",
    },
    fenghuang: {
      name: "fenghuang",
      url: "/assets/card/fenghuang.png",
    },
    shenlongdao: {
      name: "shenlongdao",
      url: "/assets/card/shenlongdao.png",
    },
    daikuan: {
      name: "daikuan",
      url: "/assets/card/daikuan.png",
    },
    shenlong: {
      name: "shenlong",
      url: "/assets/card/shenlong.png",
    },
    buyi: {
      name: "buyi",
      url: "/assets/card/buyi.png",
    },
    qinzu: {
      name: "qinzu",
      url: "/assets/card/qinzu.png",
    },
    yuanhengui: {
      name: "yuanhengui",
      url: "/assets/card/yuanhengui.png",
    },
    renmianshu: {
      name: "renmianshu",
      url: "/assets/card/renmianshu.png",
    },
  },
  obj: {
    red_cheng: {
      id: "0",
      name: "red_cheng",
      url: "/assets/gameobj/red_cheng.png",
    },
    red_warrior: {
      id: "0",
      name: "red_warrior",
      url: "/assets/gameobj/red_warrior.png",
    },
    red_monk: {
      id: "0",
      name: "red_monk",
      url: "/assets/gameobj/red_monk.png",
    },
    red_general: {
      id: "0",
      name: "red_general",
      url: "/assets/gameobj/red_general.png",
    },
    purple_cheng: {
      id: "0",
      name: "purple_cheng",
      url: "/assets/gameobj/purple_cheng.png",
    },
    purple_warrior: {
      id: "0",
      name: "purple_warrior",
      url: "/assets/gameobj/purple_warrior.png",
    },
    purple_monk: {
      id: "0",
      name: "purple_monk",
      url: "/assets/gameobj/purple_monk.png",
    },
    purple_general: {
      id: "0",
      name: "purple_general",
      url: "/assets/gameobj/purple_general.png",
    },
    green_cheng: {
      id: "0",
      name: "green_cheng",
      url: "/assets/gameobj/green_cheng.png",
    },
    green_warrior: {
      id: "0",
      name: "green_warrior",
      url: "/assets/gameobj/green_warrior.png",
    },
    green_monk: {
      id: "0",
      name: "green_monk",
      url: "/assets/gameobj/green_monk.png",
    },
    green_general: {
      id: "0",
      name: "green_general",
      url: "/assets/gameobj/green_general.png",
    },
    yellow_cheng: {
      id: "0",
      name: "yellow_cheng",
      url: "/assets/gameobj/yellow_cheng.png",
    },
    yellow_warrior: {
      id: "0",
      name: "yellow_warrior",
      url: "/assets/gameobj/yellow_warrior.png",
    },
    yellow_monk: {
      id: "0",
      name: "yellow_monk",
      url: "/assets/gameobj/yellow_monk.png",
    },
    yellow_general: {
      id: "0",
      name: "yellow_general",
      url: "/assets/gameobj/yellow_general.png",
    },
    blue_cheng: {
      id: "0",
      name: "blue_cheng",
      url: "/assets/gameobj/blue_cheng.png",
    },
    blue_warrior: {
      id: "0",
      name: "blue_warrior",
      url: "/assets/gameobj/blue_warrior.png",
    },
    blue_monk: {
      id: "0",
      name: "blue_monk",
      url: "/assets/gameobj/blue_monk.png",
    },
    blue_general: {
      id: "0",
      name: "blue_general",
      url: "/assets/gameobj/blue_general.png",
    },
    war_0: {
      name: "war_0",
      url: "/assets/gameobj/war/1.jpg",
    },
    war_1: {
      name: "war_1",
      url: "/assets/gameobj/war/2.jpg",
    },
    war_2: {
      name: "war_2",
      url: "/assets/gameobj/war/3.jpg",
    },
    war_3: {
      name: "war_3",
      url: "/assets/gameobj/war/4.jpg",
    },
    war_4: {
      name: "war_4",
      url: "/assets/gameobj/war/5.jpg",
    },
    war_5: {
      name: "war_5",
      url: "/assets/gameobj/war/6.jpg",
    },
    war_6: {
      name: "war_6",
      url: "/assets/gameobj/war/7.jpg",
    },
    war_7: {
      name: "war_7",
      url: "/assets/gameobj/war/8.jpg",
    },
    boqvan_obj: {
      name: "boqvan_obj",
      url: "/assets/gameobj/boqvan_obj.png",
    },
    kulougui_obj: {
      name: "kulougui_obj",
      url: "/assets/gameobj/kulougui_obj.png",
    },
    youling_obj: {
      name: "youling_obj",
      url: "/assets/gameobj/youling_obj.png",
    },
    hunpogui_obj: {
      name: "hunpogui_obj",
      url: "/assets/gameobj/hunpogui_obj.png",
    },
    shenlong_obj: {
      name: "shenlong_obj",
      url: "/assets/gameobj/shenlong_obj.png",
    },
    yuanhengui_obj: {
      name: "yuanhengui_obj",
      url: "/assets/gameobj/yuanhengui_obj.png",
    },
    red_bottom: {
      name: "red_bottom",
      url: "/assets/gameobj/red_bottom.png",
    },
    purple_bottom: {
      name: "purple_bottom",
      url: "/assets/gameobj/purple_bottom.png",
    },
    green_bottom: {
      name: "green_bottom",
      url: "/assets/gameobj/green_bottom.png",
    },
    blue_bottom: {
      name: "blue_bottom",
      url: "/assets/gameobj/blue_bottom.png",
    },
    yellow_bottom: {
      name: "yellow_bottom",
      url: "/assets/gameobj/yellow_bottom.png",
    },
    renmianshu_obj: {
      name: "renmianshu_obj",
      url: "/assets/gameobj/renmianshu_obj.png",
    },
  },
  ui: {
    select: {
      name: "select",
      url: "/assets/ui/select.png",
    },
  },
};
