<script setup>
import { ref } from "vue";
import Header from "./Header.vue";
import * as Colyseus from "colyseus.js";
import { getUsersInfo, getRankApi } from "../api/login";
import router from "@/router";

const rankList = [
  {
    time: "【2023/11/26】",
    content: ["---修复xxx"],
  },
];
</script>

<template>
  <div class="home-wrapper">
    <Header></Header>
    <div class="main-wrapper">
      <div class="room-wrapper">
        <div class="room-header">
          <div class="title">公告</div>
        </div>
        <div class="room-list">
          <div v-for="(item, i) in rankList" :key="i" class="room-item">
            <div class="item-title">{{ item.time }}</div>
            <div class="item-content" v-for="(it, id) in item.content">{{ it }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-wrapper {
  height: calc(100% - 100px);
}

.main-wrapper {
  padding: 64px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-info {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}

.user-name {
  font-size: 24px;
}

.room-wrapper {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  height: 100%;
  overflow-y: scroll;
}

.title {
  font-size: 24px;
}

.room-header {
  display: flex;
  align-items: center;
}

.refresh {
  cursor: pointer;
  margin-left: 16px;
}

.room-item {
  padding: 16px 8px;
  border-bottom: 1px solid #c7000b;
  margin: 0 108px;
}

.room-input {
  border-radius: 16px;
  margin-left: 108px;
  height: 32px;
  padding: 0 8px;
}

.create-room {
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid #1f1f1f;
  padding: 4px 8px;
  border-radius: 16px;
}

.user-id {
  font-size: 24px;
  font-weight: 600;
}

.flex-item {
  flex-grow: 1;
  width: 20%;
}

.item-title {
  font-size: 24px;
  font-weight: 600;
}

.item-content {
  padding-left: 16px;
}
</style>
