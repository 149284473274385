<script setup>
import { ref } from "vue";
import Header from "./Header.vue";
import * as Colyseus from "colyseus.js";
import { getUsersInfo, getRankApi } from "../api/login";
import router from "@/router";

const isProduction = true;

const client = new Colyseus.Client(`${isProduction ? "ws://43.136.33.25:2567" : "ws://localhost:2567"}`);

const userInfo = ref();

let param = localStorage.getItem("s_id");
if (param) {
  getUsersInfo(JSON.parse(param)).then((res) => {
    if (!res.data) {
      router.push({ name: "login" });
    } else {
      localStorage.setItem("rs_userinfo", JSON.stringify(res.data));
      userInfo.value = res.data;
    }
  });
} else {
  router.push({ name: "login" });
}

const rankList = ref();
getRankApi().then((res) => {
  if (res.data) {
    rankList.value = res.data;
  } else {
  }
});
</script>

<template>
  <div class="home-wrapper">
    <Header></Header>
    <div class="main-wrapper">
      <div class="user-info">
        <div class="user-name">{{ userInfo?.username }}</div>
      </div>
      <div class="room-wrapper">
        <div class="room-header">
          <div class="title">排行榜</div>
        </div>
        <div class="room-list">
          <div v-for="(item, i) in rankList" :key="i" class="room-item">
            <div class="user-id flex-item">{{ `${i + 1} ${item.username}` }}</div>
            <div class="user-id flex-item">{{ `rank分:${item.score}` }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-wrapper {
  height: calc(100% - 100px);
}

.main-wrapper {
  padding: 64px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-info {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}

.user-name {
  font-size: 24px;
}

.room-wrapper {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  height: 100%;
  overflow-y: scroll;
}

.title {
  font-size: 24px;
}

.room-header {
  display: flex;
  align-items: center;
}

.refresh {
  cursor: pointer;
  margin-left: 16px;
}

.room-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid #c7000b;
  margin: 0 108px;
}

.room-input {
  border-radius: 16px;
  margin-left: 108px;
  height: 32px;
  padding: 0 8px;
}

.create-room {
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid #1f1f1f;
  padding: 4px 8px;
  border-radius: 16px;
}

.user-id {
  font-size: 24px;
  font-weight: 600;
}

.flex-item {
  flex-grow: 1;
  width: 20%;
}
</style>
