import { createRouter, createWebHashHistory } from "vue-router";
import Game from "@/components/Game";
import Login from "@/components/Login";
import Home from "@/components/Home";
import Rank from "@/components/Rank";
import Notice from "@/components/Notice";

const routes = [
  {
    name: "game",
    path: "/game",
    component: Game,
  },
  {
    name: "login",
    path: "/login",
    component: Login,
  },
  {
    name: "home",
    path: "/home",
    component: Home,
    meta: {
      prefetchResources: [
        "../../assets/background/table.jpg",
        "../../assets/card/1.png",
        "../../assets/card/2.png",
        "../../assets/card/3.png",
        "../../assets/card/4.png",
        "../../assets/card/5.png",
        "../../assets/card/bet.jpg",
      ],
    },
  },
  {
    name: "rank",
    path: "/rank",
    component: Rank,
  },
  {
    name: "notice",
    path: "/notice",
    component: Notice,
  },
  {
    path: "/:pathMatch(.*)",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
