<script setup></script>

<template>
  <router-view></router-view>
</template>

<style lang="scss">
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

canvas {
  position: fixed;
  top: 0;
}
</style>
