<script setup>
import { ref } from "vue";
import Header from "./Header.vue";
import * as Colyseus from "colyseus.js";
import { getUsersInfo } from "../api/login";
import router from "@/router";
import isProduction from "../gameconfig";
import cardData from "../game/data/data";

const client = new Colyseus.Client(`${isProduction ? "ws://43.136.33.25:2567" : "ws://localhost:2567"}`);

const roomList = ref();

const getRooms = () => {
  client.getAvailableRooms("game").then((res) => {
    roomList.value = res;
  });
};

getRooms();

const userInfo = ref();

let param = localStorage.getItem("s_id");

if (param) {
  router.push({ name: "login" });
  getUsersInfo(JSON.parse(param)).then((res) => {
    if (!res.data) {
      router.push({ name: "login" });
    } else {
      localStorage.setItem("rs_userinfo", JSON.stringify(res.data));
      userInfo.value = res.data;
    }
  });
} else {
  router.push({ name: "login" });
}

const roomName = ref();

const createRoom = () => {
  if (!roomName.value) {
    roomName.value = `${userInfo.value.username}的游戏`;
  }
  localStorage.setItem("rs_roomname", roomName.value);
  localStorage.setItem("rs_room_opt", 1);
  router.push({ name: "game" });
};

const joinRoom = (id) => {
  localStorage.setItem("rs_roomname", id);
  localStorage.setItem("rs_room_opt", 2);
  router.push({ name: "game" });
};

for (let item of []) {
  let img = new Image();
  img.src = item;
}

for (let item in cardData) {
  for (let item_0 in cardData[item]) {
    let data = cardData[item];
    let img = new Image();
    img.src = data[item_0].url;
  }
}
</script>

<template>
  <div class="home-wrapper">
    <Header></Header>
    <div class="main-wrapper">
      <div class="user-info">
        <div class="user-name">{{ userInfo?.username }}</div>
      </div>
      <div class="room-wrapper">
        <div class="room-header">
          <div class="title">在线房间列表</div>
          <div class="refresh" @click="getRooms()">刷新列表</div>
          <input class="room-input" v-model="roomName" placeholder="请输入房间名称" />
          <div class="create-room" @click="createRoom()">创建</div>
          <div class="create-tps">*如果所有玩家准备就绪游戏没有开始，请重新建房</div>
        </div>
        <div class="room-list">
          <div v-for="(item, i) in roomList" :key="i" class="room-item">
            <div class="room-name">{{ item?.metadata.room_name }}</div>
            <div class="room-persons">{{ `${item.clients}/${item.maxClients}` }}</div>
            <div class="enter-button" @click="joinRoom(item.roomId)">加入房间</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-wrapper {
  height: calc(100% - 100px);
}

.main-wrapper {
  padding: 64px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-info {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}

.user-name {
  font-size: 24px;
}

.room-wrapper {
  background: white;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  border-radius: 12px;
  height: 100%;
}

.title {
  font-size: 24px;
}

.room-header {
  display: flex;
  align-items: center;
}

.refresh {
  cursor: pointer;
  margin-left: 16px;
}

.room-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid #c7000b;
}

.room-input {
  border-radius: 16px;
  margin-left: 108px;
  height: 32px;
  padding: 0 8px;
}

.create-room {
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid #1f1f1f;
  padding: 4px 8px;
  border-radius: 16px;
  margin-right: 32px;
}

.room-name {
  font-size: 20px;
  font-weight: 600;
}

.enter-button {
  border: 1px solid #1f1f1f;
  border-radius: 16px;
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  cursor: pointer;
}
</style>
