<script setup>
import { reactive } from "vue";
import { login, getUserInfoByIds, register } from "../api/login";
import router from "@/router";

const userInfo = reactive({
  username: "",
  loginId: "",
  password: "",
});

const validate = (field) => {
  if (!Array.isArray(field)) {
    return Promise.reject("");
  }
  return Promise.resolve(field.every((f) => userInfo[f]));
};

const toLogin = () => {
  return getUserInfoByIds(userInfo)
    .then((res) => {
      if (200 === res.code) {
        if (res.data && !res.data.length) {
          return validate(["username"])
            .then((pass) => {
              if (pass) {
                if (userInfo["username"].length > 8) {
                  console.warn("昵称超长");
                  alert("昵称超过8个字符");
                  return Promise.reject();
                }
                return Promise.resolve();
              } else {
                console.warn("昵称不能为空");
                alert("昵称不能为空");
                return Promise.reject();
              }
            })
            .then(() => register(userInfo));
        }
      } else {
        return Promise.reject(res.message);
      }
    })
    .then(() => login(userInfo))
    .catch((msg) => {
      console.warn(msg);
    });
};
const handleToLogin = () => {
  validate(["loginId", "password"])
    .then((pass) => {
      if (!pass) {
        console.warn("账号或密码不能为空");
        alert("账号或密码不能为空");
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    })
    .then(() => {
      toLogin().then((res) => {
        if (res && 200 === res.code) {
          const data = {
            userId: res.data.userId,
            token: res.data.token,
          };
          window.localStorage.setItem("s_id", JSON.stringify(data));
          //window.location.href = "/Home";
          router.push({ name: "home" });
        } else {
          if (res) {
            alert(res.message);
          }
        }
      });
    });
};
</script>

<template>
  <div class="text">
    <div>网页版存在BUG，已停止开发维护</div>
    <div>正在开发客户端版~</div>
  </div>
  <div class="a">
    <div class="c">
      <div class="d">
        <h1>登录/注册</h1>
        <input v-model="userInfo.username" type="text" class="e" placeholder="昵称" />
        <input v-model="userInfo.loginId" type="text" class="e" placeholder="账号(大于等于6位)" />
        <input v-model="userInfo.password" type="password" class="e" placeholder="密码" />
        <div>* 没有账号会自动注册，已有账号只需要输入账号密码</div>
        <button disabled class="g" @click.prevent="handleToLogin">一键登录</button>
      </div>
    </div>
    <div class="b"></div>
  </div>
  <div class="bottom">
    <a href="https://beian.miit.gov.cn/">粤ICP备2023135204号-1</a>
  </div>
</template>

<style>
body {
  /* 设置背景渐变 */
  background-image: linear-gradient(to left, #891528, #2a3237);
}

a {
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
  }
}

.text {
  margin-top: 200px;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: white;
}

.a {
  position: relative;
  top: 100px;
  width: 1100px;
  height: 550px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
  display: flex;
  margin: 0 auto;
}
.b {
  width: 800px;
  height: 550px;
  background-image: url("../../public/assets/login.jpg");
  /* 让图片适应大小 */
  background-size: cover;
  background-position: right;
}
.c {
  width: 300px;
  height: 550px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d {
  width: 250px;
  height: 500px;
}
.d h1 {
  font: 900 30px "";
}
.e {
  width: 230px;
  margin: 20px 0;
  outline: none;
  border: 0;
  padding: 10px;
  border-bottom: 3px solid rgb(80, 80, 170);
  font: 900 16px "";
}
.account-operation-area {
  float: right;
  margin: 10px 0;
}
.g {
  color: white;
  position: absolute;
  margin: 20px;
  bottom: 40px;
  display: block;
  width: 200px;
  height: 60px;
  font: 900 30px "";
  text-decoration: none;
  line-height: 50px;
  border-radius: 30px;
  background-image: linear-gradient(to left, #891528, #2a3237);
  text-align: center;
  filter: grayscale(100%);
  cursor: not-allowed;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  text-align: center;
  line-height: 80px;
}
</style>
